import React from 'react'
import { useNavigate } from 'react-router-dom'

const About = () => {
  const Navigate = useNavigate()
  return (
    <div className='h-screen grid justify-items-center  bg-gradient-to-r from-shore1 to-shore2'>
        <div className="grid w-full  h-fit   ">
            <div  className="grid gap-5 lg:grid-cols-2" >
              <div className="flex gap-2 justify-items-center  lg:text-5xl lg:col-span-2  font-letter">
                <div className="text-center font-bold9 w-full lg:text-start  lg:font-letter "> Shree Shyam Consultancy Services</div>
              </div>
              <div className=" grid justify-items-center  lg:absolute right-10 top-8">
                <ul className="flex gap-5  text-black lg:text-xl">
                    <li className="cursor-pointer  hover:text-black"  onClick={()=>{Navigate("/")}} >Home</li>
                    <li className="cursor-pointer  hover:text-black" onClick={()=>{Navigate('/Contact')}} >Contact</li>
                    <li className="cursor-pointer  hover:text-black" onClick={()=>{Navigate('/Services')}} >Our Services</li>
                    <li className="cursor-pointer  hover:text-black" onClick={()=>{Navigate('/About')}} >About Us</li>
                </ul>
              </div>
            </div>
            </div>
        <div className=' lg:w-2/3'>
        <div className='  text-xl lg:text-3xl font-bold9'>Accounting &  <span className='text-blue-500'>Consultancy </span>Services </div>
        <div className='text-gray-600 font-letter'>SSCS helps people and companies to address the accounting and tax related impact associated with large , complex projects. Weather expanding into new markets or transforming operations and structures , our global team of consultants has expertise in accounting and tax regulations,and hands on experience to provide practical solutios to complex challanges.</div>
        </div>
        <div className='lg:w-2/3'>
            <div className='text-xl lg:text-3xl font-bold9  '>Our <span className='text-blue-500'>experience</span> </div>
            <div className='text-gray-600 font-letter'>We have the experience of 5 years to handle our clients and provide them a good services</div>
        </div>
        <div>
            <div className='text-xl lg:text-3xl font-bold9  '>Our <span className='text-blue-500'>Vision</span> </div>
            <div className='text-gray-600 font-letter'>We have a vision of taking tax related things in next level , where clients will be taking no tension of anything about financial-concerns.</div>
        </div>
    </div>
  )
}

export default About