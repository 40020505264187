import React from 'react'
import { useEffect } from 'react'

const Test = async() => {
   

    useEffect(() => {
        res()
    }, [])
    
    const res =  fetch("/aaa", {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }).then((res) => res.json());
      console.log(res);
     

  return (
    <div>
        {
            // res.map((index)=>{
            //     <div>{index.url}</div>
            // })
        }
    </div>
  )
}

export default Test