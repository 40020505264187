import React from "react";
import { BsInstagram } from "react-icons/bs";
import { AiOutlineLinkedin } from "react-icons/ai";
import {BsTwitter} from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const Landingpage = (e) => {
  const Navigate = useNavigate()
  const Submit = async () => {
    const Name = document.getElementById("name").value;
    const Contact = document.getElementById("Contact").value;
    // console.log()

    const res = await fetch("/contact", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ Name, Contact }),
    }).then((res) => res.json());
    console.log(res);
    // const res = await fetch("/").then((res)=>res.json())
    // console.log(res)
  };

  return (
    <>
      <div className=" w-screen  h-fit       grid   gap-5 ">
        <div className="grid w-full  h-fit   ">
            <div  className="grid gap-5 lg:grid-cols-2" >
              <div className="flex gap-2 justify-items-center lg:text-5xl lg:col-span-2  font-letter">
                <img   className="w-20" src="logo2.png" ></img>
                <div className="text-center font-bold9 lg:font-letter"> Shree Shyam Consultancy Services</div>
              </div>
              <div className=" grid justify-items-center  lg:absolute right-10 top-8">
                <ul className="flex gap-5  text-gray-500 lg:text-xl">
                    <li className="cursor-pointer  hover:text-black" >Home</li>
                    <li className="cursor-pointer  hover:text-black" onClick={()=>{Navigate('/Contact')}} >Contact</li>
                    <li className="cursor-pointer  hover:text-black" onClick={()=>{Navigate('/Services')}} >Our Services</li>
                    <li className="cursor-pointer  hover:text-black" onClick={()=>{Navigate('/About')}} >About Us</li>
                </ul>
              </div>
            </div>
           
          <div className="grid lg:grid-cols-2  gap-5   mt-5 items-end row-span-2">
            <div className="font-bold9    text-3xl lg:text-4xl  ">
              Accounting & <span className="text-blue-700">Consultancy</span>{" "}
              Services
            </div>

            <div className="w-full h-full row-span-2 grid justify-items-center ">
              <img src="./home1.png"></img>
            </div>
            <div className="h-full">
              <div className="font-letter justify-self-end  text-start ml-2  text-lg text-gray-500  row-span-1 ">
                SSCS helps people and companies to address the accounting and
                tax related impact associated with large , complex projects.
                Weather expanding into new markets or transforming operations
                and structures , our global team of consultants has expertise in
                accounting and tax regulations,and hands on experience to
                provide practical solutios to complex challanges.
              </div>

              <button
                type="submit"
                className=" w-2/3  ml-4 mt-4 bg-transparent hover:bg-blue-700 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-700 hover:border-transparent rounded mb-4 lg:w-1/3"
                  onClick={()=>{Navigate('/Contact')}}  >
                {" "}
                Get in touch
              </button>
            </div>
          </div>

          <footer className=" w-full    grid    lg:justify-items-center  lg:grid-cols-3  border-t-2 border-gray-300  lg:absolute bottom-1 mt-5  ">
            {/* <div> */}
              {/* <ul className="text-lg text-center lg:text-start font-letter"> */}
                <div className=""><span className="font-bold9 ">Adress :</span> Flat no 9/449 Arvali Vihar Apartment Bhiwadi Rajasthan</div>
                <div className=""><span className="font-bold9 ">Appointment</span>: Mon-Sat 10:00-6:00/Sunday:CLOSED</div>
                <div className=""><span className="font-bold9 ">Phone </span>: 8442042024 / 9466451294</div>
              
              {/* </ul> */}
            {/* </div> */}
            </footer>
        </div>
      </div>
    </>
  );
};

export default Landingpage;
