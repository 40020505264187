import React ,{useRef} from "react";
import emailjs from '@emailjs/browser';
import { useNavigate } from "react-router-dom";
const Contact = () => {

  

  const form = useRef()
 
  const sendEmail = (e) => {
      e.preventDefault();
      console.log("in email")
  
      emailjs.sendForm('service_rrvpev9', 'template_nc1fjop', form.current, 'IQygafYCGg9U-cCWR')
        .then((result) => {
            console.log(result.text);
            alert('You message has been recieved well get in touch soon')
        }, (error) => {
            console.log(error.text);
            alert('an error occured')
        });
    };


  const Navigate = useNavigate()
  return (
    <>
      <div className="w-full h-screen   grid justify-items-center items-center">
        <div>
        <div className=" grid justify-items-center  lg:absolute right-10 top-8">
                <ul className="flex gap-5  text-gray-500 text-sm lg:text-xl">
                    <li className="cursor-pointer  hover:text-black"  onClick={()=>{Navigate("/")}} >Home</li>
                    <li className="cursor-pointer  hover:text-black" onClick={()=>{Navigate('/Contact')}} >Contact</li>
                    <li className="cursor-pointer  hover:text-black" onClick={()=>{Navigate('/Services')}} >Our Services</li>
                    <li className="cursor-pointer  hover:text-black" onClick={()=>{Navigate('/About')}} >About Us</li>
                </ul>
              </div>
        </div>
        <div className="   md:grid grid-cols-2 gap-8 md:w-11/12">
          <div>
            <div className=" md:text-5xl text-2xl w-full md:font-abc text-center md:text-start text-blue-700 m-4">
              GET IN TOUCH
            </div>
            <div className=" text-sm md:text-lg md:font-normal m-6 font-letter">
              Email : monubhardwaj865@gmail.com , advocatemonubhrdwaj@gmail.com
            </div>
            <div className=" text-sm md:text-lg md:font-normal m-6 font-letter">
              Phone : 8442042024 ,9466451294
            </div>
            <div className=" text-sm md:text-lg  md:font-normal m-6 font-letter">
              Adress :
              <span className="">
                {" "}
                Flat no 9/449 Arvali Vihar Apartment Bhiwadi Rajasthan
              </span>{" "}
            </div>
          </div>
          <div>
          <form  ref={form} className='m-4 grid gap-2'>
                        <div>
                          <div className="text-2xl font-letter text-blue-700">Send Us Your Request</div>
                        <label  className="block mb-2 text-sm font-letter text-gray-900 ">Name </label>
                        <input type="name"  name="user_name"id="Name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="Name" required />
                            </div>      <div>
                        <label  className="block mb-2 text-sm font-letter text-gray-900 ">Email </label>
                        <input type="email"  name="user_email" id="Email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="Email" required />
                            </div>      <div>
                        <label  className="block mb-2 text-sm font-letter text-gray-900 ">Phone </label>
                        <input  name='phone' type="text" id="Phone" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="Phone" required />

                            </div>
                           
                            <div className='text-lg '>Leave us a message</div>
                            <textarea className='border h-44' name="message" placeholder='Leave us a message'></textarea>
                       
                            
        
                            <button type='submit' className=" bg-transparent hover:bg-blue-700 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-700 hover:border-transparent rounded mb-4 w-1/3"
                            onClick={(e)=>{sendEmail(e)}}   > Submit</button> 
                            
                        </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
