import React from 'react'
import { useNavigate } from 'react-router-dom'

const Services = () => {
  const Navigate = useNavigate()
  return (
    <div className='h-screen w-fit grid   justify-items-center items-center '>
        <div className='lg:text-5xl font-letter  '>
          <div className='w-full text-center'>Services We Offer</div>
          <div className=" grid justify-items-center  lg:absolute right-10 top-8">
                <ul className="flex gap-5  text-gray-500 text-sm lg:text-xl">
                    <li className="cursor-pointer  hover:text-black"  onClick={()=>{Navigate("/")}} >Home</li>
                    <li className="cursor-pointer  hover:text-black" onClick={()=>{Navigate('/Contact')}} >Contact</li>
                    <li className="cursor-pointer  hover:text-black" onClick={()=>{Navigate('/Services')}} >Our Services</li>
                    <li className="cursor-pointer  hover:text-black" onClick={()=>{Navigate('/About')}} >About Us</li>
                </ul>
              </div>
        </div>
       
       <div className='w-full grid  gap-5 justify-items-center   items-center lg:w-11/12 lg:grid-cols-4'>
       <div className='border shadow-lg cursor-pointer h-full hover:shadow-2xl ' >
        <img src='../gst.webp' className='w-full' alt=''></img>
        <div className='text-center text-xl font-letter p-5 text-orange-500'>GST</div>
        <div className='font-letter p-5 text-center '>GST (Goods and Services Tax) is one indirect tax for the whole nation. It is the resultant tax after subsuming major Central and State indirect taxes. GST is a destination based tax levied on the consumption of goods and services across the nation, thus rendering the country one unified common market</div>
        
       </div>  
       <div className='border shadow-lg cursor-pointer h-full hover:shadow-2xl  ' >
        <img src='../company.jpg' className='w-full'  alt=''></img>
        <div className='text-center text-xl text-orange-500 font-letter p-5'>Company Registration</div>
        <div className='p-3 font-letter text-center'>
Setting up a private limited company is one of the highly recommended ways to start a business in India . This type of company offers limited liability for its shareholders with certain restrictions placed on the ownership. An LLP has partners, who own and manage the business. Whereas in private limited company registration , directors may be different from shareholders.</div>
       
       </div> 
        <div className='border shadow-lg cursor-pointer h-full  hover:shadow-2xl ' >
          <img src='../tds3.jpg' className='w-full'  alt=''></img>
          <div className='text-center font-letter text-xl text-orange-500 p-5'>TDS return filling</div>
          <div className='p-3 font-letter text-center'>The acknowledgement form need to be sent to PAN service provider. Before sending this, you will need to attach proof of identity, proof of address and proof of date of birth document alongwith the acknowledgment form. Your name which is mentioned in these documents, should be the same as that is being asked in the PAN application form. Please take care of this at the time of filling in PAN application form.</div>
      
          </div>
          <div className='border shadow-lg cursor-pointer h-full hover:shadow-2xl  ' >
        <img src='../tax.jpg' className='w-full'  alt=''></img>
        <div className='text-center text-xl text-orange-500 font-letter p-5'>Income tax return filling</div>
        <div className='p-3 font-letter text-center'>Every person having taxable income and whose accounts are not liable to audit must file an Income Tax Return. If total income exceeds Rs. 5 lakh, it is mandatory to file the return online. Self-assessment tax liability should be paid before filing Income Tax Return; otherwise return will be treated as defective.</div>
       
       </div> 
          <div>
        
       </div>
       </div>

    </div>
  )
}

export default Services