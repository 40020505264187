import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Landingpage from './assets/Landingpage';
import Contact from './assets/Contact';
import Services from './assets/Services';
import Test from './assets/Test';
import About from './assets/About';

function App() {
  return (
   <>
   <Routes>
    <Route path='/' element={<Landingpage/>}/>
    <Route path='/Contact' element={<Contact/>}/>
    <Route path='/Services' element={<Services/>}/>
    <Route path='/test' element={<Test/>}/>
    <Route path='/About' element={<About/>}/>

   </Routes>
   </>
  );
}

export default App;
